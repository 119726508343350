@charset "utf-8";

// Define defaults for each variable.

$base-font-family: nemon, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   20px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.975 !default;
$base-line-height: 1.3 !default;

$spacing-unit:     56px !default;

$heading-color:    #EBEEEF !default;
$text-color:       #EBDABD !default;
$background-color: #304749 !default; 
$brand-color:      #EB8053 !default;
$secondary-color:  #FFD600 !default;
$third-color:      #87DEE1 !default;

$grey-color:       #415557 !default;
$grey-color-light: #5D6F71 !default;
$grey-color-dark:  #263638 !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    680px !default;
$on-palm:          600px !default;
$on-laptop:        680px !default;

// // Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  // "minima/layout",
  "minima/syntax-highlighting"
;

@font-face {
  font-family: 'nemon';
  src: url('fonts/ne-mon-var.woff2') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'nemon';
  font-style: italic;
  src: url('fonts/ne-mon-var.woff2') format('truetype');
  font-variation-settings: "ital" 900;
}

@font-face {
  font-family: 'monument';
  src: url('fonts/monument-ultrabold.woff') format('truetype');
  font-style: normal;
}